import Carousel from 'react-bootstrap/Carousel';
import React, { useEffect, useState } from 'react'
import Testmonialss from './Testmonialss';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './App.css'
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';



const Home = () => {

    <HelmetProvider>
        <Helmet>

            <meta charSet="utf-8" />
            <title>Best General Physician in Guntur </title>
            <meta property="og:title" content="Best General Physician in Guntur" />
            <meta property="og:site_name" content="Best General Physician in Guntur " />
            <meta property="og:description"
                content="Dr. Kalyan Chakravarthy Koganti, a visionary and dedicated medical professional, is the founder of CID Centre For Infectious Diseases." />

            {/* <meta property="og:image" content="http://helphospital.in/Help%20LOGO%20(1).png" /> */}
        </Helmet>
    </HelmetProvider>
    // const [indicatorsControl, setIndicatorsControl] = useState(true)

    // useEffect(() => {

    //     // iPhone X width, for example
    //     if (window.innerWidth <= 499) {
    //         setIndicatorsControl(false);
    //     }
    // }, [])
    return (

        <>
            <div id='Topmargin1' >
                <Carousel className=" homeclass" variant='dark' controls={false} indicators={false} pause={false} >
                    <Carousel.Item className=" homeclass1">
                        <img
                            className="d-block w-100"
                            src="new_banner chnage _000v1.png"
                            alt="First slide"
                        // style={{animation:'zoom 15s'}}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="WhatsApp Image 2024-01-27 at 12.28.39 PM.jpeg"
                            alt="SECOND slide"
                        // style={{animation:'zoom 15s'}}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="new_banner_032.png"
                            alt="THIRD slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="WhatsApp Image 2024-01-27 at 12.28.06 PM.jpeg"
                            alt="FOURTH slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className='fluid-container AddressWeb' style={{ backgroundColor: 'red' }}>
                <div className='container'>
                    <div className='row'>
                        <center>
                            <div className='col'>
                                <p className='mt-2' style={{ color: 'white' }}><span ><b><a href='https://helphospital.in/' style={{ color: 'white' }}>Help Hospital</a></b></span> :  4/5, Arundelpet, Guntur, AP / <span ><b><a style={{ color: 'white' }} href='https://www.google.com/search?q=shri+hospital+guntur&sca_esv=daf00478b2424d26&rlz=1C1JJTC_enIN1028IN1028&sxsrf=ACQVn08y7iFz-TapnA577Q-L0do3aKe-8g%3A1707108285286&ei=vWfAZZqQEcfk4-EPl_qQsA0&gs_ssp=eJzj4tVP1zc0TMuoKM8xLjc0YLRSNagwTjRJNDdNS0lLTUkxsLAwtDKoSE0xTDNPMweqTDEwTU418BIpzijKVMjILy7ILEnMUUgvzSspLQIAbkwYOg&oq=shrI+hospital+guntur&gs_lp=Egxnd3Mtd2l6LXNlcnAiFHNockkgaG9zcGl0YWwgZ3VudHVyKgIIADITEC4YrwEYxwEYgAQYigUYjgUYJzIFEAAYgAQyBRAAGIAEMgYQABgHGB4yBBAAGB4yBhAAGAgYHjICECYyCxAAGIAEGIoFGIYDMiAQLhivARjHARiABBiKBRiOBRiXBRjcBBjeBBjgBNgBAUjnKVDwC1iMFnABeAGQAQCYAfABoAGcBaoBAzItM7gBAcgBAPgBAcICChAAGEcY1gQYsAPCAhMQLhiABBiKBRhDGMcBGK8BGLADwgIHECMYsAIYJ8ICDRAuGA0YrwEYxwEYgATCAgcQABiABBgNwgIIEAAYCBgHGB7CAhwQLhgNGK8BGMcBGIAEGJcFGNwEGN4EGOAE2AEB4gMEGAAgQYgGAZAGBroGBggBEAEYFA&sclient=gws-wiz-serp'><span > Shri Hospital</span></a></b></span> : Donka Road, Guntur, AP</p>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
            <div className='fluid-container AddressMobile' style={{ backgroundColor: 'red' }}>
                <div className='container'>
                    <div className='row'>
                        <center>
                            <div className='col'>
                                <p className='mt-2' style={{ color: 'white' }}><span ><b><a href='https://helphospital.in/' style={{ color: 'white' }}>Help Hospital</a></b></span> :  4/5, Arundelpet, Guntur, AP / <br></br><span ><b><a style={{ color: 'white' }} href='https://www.google.com/search?q=shri+hospital+guntur&sca_esv=daf00478b2424d26&rlz=1C1JJTC_enIN1028IN1028&sxsrf=ACQVn08y7iFz-TapnA577Q-L0do3aKe-8g%3A1707108285286&ei=vWfAZZqQEcfk4-EPl_qQsA0&gs_ssp=eJzj4tVP1zc0TMuoKM8xLjc0YLRSNagwTjRJNDdNS0lLTUkxsLAwtDKoSE0xTDNPMweqTDEwTU418BIpzijKVMjILy7ILEnMUUgvzSspLQIAbkwYOg&oq=shrI+hospital+guntur&gs_lp=Egxnd3Mtd2l6LXNlcnAiFHNockkgaG9zcGl0YWwgZ3VudHVyKgIIADITEC4YrwEYxwEYgAQYigUYjgUYJzIFEAAYgAQyBRAAGIAEMgYQABgHGB4yBBAAGB4yBhAAGAgYHjICECYyCxAAGIAEGIoFGIYDMiAQLhivARjHARiABBiKBRiOBRiXBRjcBBjeBBjgBNgBAUjnKVDwC1iMFnABeAGQAQCYAfABoAGcBaoBAzItM7gBAcgBAPgBAcICChAAGEcY1gQYsAPCAhMQLhiABBiKBRhDGMcBGK8BGLADwgIHECMYsAIYJ8ICDRAuGA0YrwEYxwEYgATCAgcQABiABBgNwgIIEAAYCBgHGB7CAhwQLhgNGK8BGMcBGIAEGJcFGNwEGN4EGOAE2AEB4gMEGAAgQYgGAZAGBroGBggBEAEYFA&sclient=gws-wiz-serp'><span > Shri Hospital</span></a></b></span> : Donka Road, Guntur, AP</p>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
            <div className='container mt-5' >
                <h2 data-aos="flip-right" data-aos-duration="3000" style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Latest News</span>
                </h2>
                <div className='row' data-aos="zoom-in" data-aos-duration="3000" >
                    <div className='col-md-6 col-sm-12 '>
                        <Card >
                            <Card.Img variant="top" src="NEWS 1.jpeg" alt='' />
                            <Card.Body>
                                <Card.Title style={{ fontSize: '17px' }} ></Card.Title>
                                <Card.Text></Card.Text>
                                {/* <Button variant="primary">Know More</Button> */}
                                {/* <Link to='/smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh'> Know More</Link> */}
                            </Card.Body>
                        </Card>
                        {/* <a href='https://www.thehindu.com/news/national/andhra-pradesh/patients-in-remote-areas-being-treated-through-smart-icu-programme-in-guntur/article67772958.ece' ><img src='Patients-in-remote-area.jpeg' alt='news' style={{ width: '100%', }} className='newsImage'></img></a>
                        <a href='https://www.thehindu.com/news/national/andhra-pradesh/patients-in-remote-areas-being-treated-through-smart-icu-programme-in-guntur/article67772958.ece' style={{textDecoration:'none'}} ><h6 className='mt-4 text-center' style={{color:'red'}}>Patients in remote areas being treated through smart ICU programme in Guntur</h6></a> */}
                    </div>
                    <div className='col-md-6 col-sm-12'>

                        <Card >
                            <Card.Img variant="top" src="News2.png" alt='' height={620}/>
                            <Card.Body>
                                <Card.Title style={{ fontSize: '17px' }} ></Card.Title>
                                <Card.Text></Card.Text>
                                {/* <Button variant="primary">Know More</Button> */}
                                {/* <Link to='/smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh'> Know More</Link> */}
                            </Card.Body>
                        </Card>
                        {/* <a href='https://timesofindia.indiatimes.com/city/vijayawada/smart-icu-launched-at-phc-in-andhra-pradesh/articleshow/104070686.cms?from=mdr'><img src='WhatsApp Image 2023-10-03 at 10.00.38 AM1.jpeg' alt='news' style={{ width: '100%', marginTop: '2.5%',}} className='newsImage'></img></a>
                        <a href='https://timesofindia.indiatimes.com/city/vijayawada/smart-icu-launched-at-phc-in-andhra-pradesh/articleshow/104070686.cms?from=mdr' style={{textDecoration:'none'}}><h6 className='mt-4 text-center' style={{color:'red'}}>Revolutionizing Rural Healthcare: Dr. Kalyan Chakravarthy Leads the Way with AI-Driven Live ICU in Guntur</h6></a> */}
                    </div>
                    {/* <div className='col-md-6 col-sm-12 mt-2'>
                        <img src=' toi.png' alt='news' style={{ width: '100%' }}></img>
                    </div> */}
                </div>
                <center>
                    <div className='row mt-3'>
                        <div className='col col-sm-12'>
                            <a href='News' className='FridayIdMoreInformation'><button >For More News Articles Visit</button></a>
                        </div>
                    </div>
                </center>
            </div>


            {/* heading..... */}
            <div data-aos="flip-right" data-aos-duration="3000" >
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Centres Of Excellence</span>
                </h1>
            </div>



            {/* cards........ */}

            <div className="container text-center facility cards mb-5"  >
                <div className="row facilityRow" style={{ textAlign: 'center', justifyContent: 'center' }}>

                    <div className='container'>
                        <div className='row' data-aos="zoom-in" data-aos-duration="3000">
                            <div className='col-lg-6 col-md-12 col-sm-12'>
                                <div className='card mb-2 ' style={{ borderRadius: '15px', border: '1px solid #000', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px' }}>
                                    <div className='card-body'>
                                        <img src='down 2.png' alt='' style={{ width: '60%', height: '25%', borderRadius: '5px', marginTop: '-10px' }}></img>

                                        <p><span style={{ color: 'rgb(245, 41, 41)' }}><b>Centre For Infectious Diseases</b></span> in first of its kind state of the microbiology unit established at Help Hospital Guntur under the eminent guidance of Dr. Kalyan Chakravarthi. This centre has the most modern blood culture system and automated identification of organic carry infection. It is also equipped with CB-NAAT(Cartridge Based  Nucleic Acid  Amplification Technology) to detect many infections like Tuberculosis using PCR.</p>
                                        <Link to='/BEST_CENTRE_FOR_INFECTIOUS_DISEASES'><Button style={{ backgroundColor: '#ff7800' }}>Know More</Button></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-sm-12'>
                                <div className='card mb-2' style={{ borderRadius: '15px', border: '1px solid #000', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                                    <div className='card-body'>
                                        <img src='atm.png' alt='' style={{ width: '60%', height: '45%', borderRadius: '5px', marginTop: '-5px' }}></img>
                                        {/* <h3 className='mt-5' style={{ color: 'rgb(245, 41, 41)', textTransform: 'uppercase' }}>Any Time Monitoring(ATM)</h3> */}
                                        <p className='mt-3'><span style={{ color: 'rgb(245, 41, 41)' }}><b>Any Time Monitoring</b></span> is an innovative and pioneering healthcare management program at Help Hospital. It addresses the need for continuous monitoring and care for patients, particularly those dealing with Non-Communicable Diseases (NCDs) such as diabetes, hypertension, heart disease, kidney disease, and others.
                                        </p>
                                        <Link to='/Any_Time_Monitoring'><Button style={{ backgroundColor: '#ff7800', marginBottom: '5%' }}>Know More</Button></Link>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='row' data-aos="zoom-in" data-aos-duration="3000">
                            <div className='col-lg-6 col-md-12 col-sm-12'>
                                <div className='card mb-2' style={{ borderRadius: '15px', border: '1px solid #000', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px' }}>
                                    <div className='card-body'>
                                        <img src='LIVE V2.jpg' alt='' style={{ width: '60%', height: '55%', borderRadius: '5px', marginTop: '-15px' }}></img>
                                        {/* <h3 style={{ color: 'rgb(245, 41, 41)', textTransform: 'uppercase', marginTop: '-15px' }}>LIVE</h3> */}
                                        {/* <h5>Lucidly Integrated Vital Care In Emergency</h5> */}
                                        <p><span><b style={{ color: 'rgb(245, 41, 41)' }}>LIVE, Lucidly Integrated Vital Care In Emergency</b></span> is another innovation under the leadership of Dr. Kalyan Chakravarthi at Shri Hospital. Using the most modern technology its more than just a Tele-ICU. Patient’s data is captured LIVE and using machine learning and artificial intelligence their healthcare statistics are constantly updated to not only intermediate but also all the important specialisations associated with patient care.</p>
                                        <Link to='/lucidly_Integrated_Vital_Care_In_Emergency_in_Guntur'><Button style={{ backgroundColor: '#ff7800' }}>Know More</Button></Link>

                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-lg-6 col-md-12 col-sm-12'>
                                <div className='card mb-2' style={{ borderRadius: '15px', border: '1px solid #000', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px' }}>
                                    <div className='card-body'>
                                        <img src='sepsis1.jpg' alt='' style={{ width: '50%', height: '25%', borderRadius: '5px' }}></img>
                                        <h3 className='mt-4' style={{ color: 'rgb(245, 41, 41)', textTransform: 'uppercase' }}>Sepsis</h3>
                                        <p>Dr. Kalyan Chakravarthy Koganti, a highly skilled and compassionate medical professional, has gathered a team of exceptional doctors specializing in critical care and infectious diseases at SHRI Hospital in Guntur. With a focus on providing top-notch medical services, Dr. Koganti and his team are dedicated to delivering the best care possible for patients suffering from sepsis. </p>
                                        <Link to='/Sepsis_and_Infectious_Diseases'><Button style={{ backgroundColor: '#ff7800' }}>Know More</Button></Link>
                                    </div>
                                </div>
                            </div> */}
                            <div className='col-lg-6 col-md-12 col-sm-12' >
                                <div className='card mb-2' style={{ borderRadius: '15px', border: '1px solid #000', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', }}>
                                    <div className='card-body'>
                                        <img src='shri fever clinic.png' alt='' style={{ width: '55%', height: '45%', borderRadius: '5px', marginTop: '-5px' }}></img>
                                        {/* <h3 className='' style={{ color: 'rgb(245, 41, 41)', textTransform: 'uppercase' }}>Fever</h3> */}
                                        <p className='mt-3'><span style={{ color: 'rgb(245, 41, 41)' }}><b>SHRI, Fever Clinic</b></span> is another initiative by Dr. Kalyan Chakravarthi at Shri Hospital Guntur it is a fully equipped centre that is backed up by the First negative prime ICU in AP, to support critical care patients who are in a very sick state. It is also supported by Bio-Fire, again the first multiplex PCR in AP to detect serious infection in 3 hours and help patients recover from critical conditions.</p>
                                        <Link to='/Best_Fever_Hospital_in_guntur'><Button style={{ backgroundColor: '#ff7800', marginBottom: '4%' }}>Know More</Button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* get to know our team of experience..... */}



            <div className='container mt-5' data-aos="flip-up" data-aos-duration="1000" >
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Get To Know More About Dr Kalyan Chakravarthy Koganti</span>
                </h1>
            </div>

            {/* another side image cards......get to know our team of experience */}

            <div className="container mb-2 " >

                <div className="row">
                    <div className="col-lg-7 mb-2">

                        <p>
                            <strong>
                                <br />
                                <br />
                                Dr Kalyan Chakravarthy Koganti<br />
                            </strong>
                        </p>
                        {/* <p style={{ textAlign: 'justify' }}>
                            Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education.
                            Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK and is the 1st ID specialist of our state.

                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            Started Center for Infectious Diseases in Guntur with state of the art Micobiology lab supported with automated culture systems, PCR and CB-NAAT technologies.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            He is also pioneer in initiating first of its kind integrated health care with remote patient monitoring .Health ATM is his brain child that helps to monitor the patients 24/7 even from home or any other remote location.
                        </p> */}
                        <p style={{ textAlign: 'justify' }}>
                            {/* Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education. 
                  Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK and is the 1st ID specialist of our state. */}
                            Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education.
                            Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK and is the 1st ID specialist of our state.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            Started Centre for Infectious Diseases at Help hospital  in Guntur with state of the art Micobiology lab supported with automated culture systems, PCR and CB-NAAT technologies.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            In Dec 2019, with the help of young and enthusiastic super specialists, started Samishta Hospital and Research Institute, SHRI, which has first state of the art critical care unit in AP with isolation cubicles supported by air handling units.
                        </p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5  p-5">
                        <img style={{ width: "70%", height: '90%', boxShadow: '10px 10px grey' }} src='KalyanChakravarthiImage.jpg' alt=''></img>
                    </div>
                </div>
            </div>

            {/* video and related content */}
            <div className='container'>
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '5px' }}>
                    <span style={{ color: "#ff7800" }}>What <span style={{ textTransform: 'lowercase' }}>is</span> Sepsis ?</span>
                </h1>

                <div className="row" >
                    <div style={{ textAlign: 'center' }} className="col-lg-5 mt-5" data-aos="fade-up-right" data-aos-duration="3000">
                        <iframe width="100%" height="250" src="https://www.youtube.com/embed/Gz-2yHN1RsI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                    <div className="col-lg-7  YoutubeContent mt-5" data-aos="fade-up" data-aos-duration="3000" >
                        <p style={{ textAlign: 'justify' }}>
                            Sepsis is a life-threatening condition triggered by infections. Rapid response is critical. Recognize symptoms like fever, rapid breathing, and confusion. Seek immediate medical attention for timely treatment. Common sources of infection include pneumonia, urinary tract infections, and bloodstream infections. Vulnerable groups, such as the elderly and those with chronic illnesses, are at higher risk. Early diagnosis through physical exams and tests is essential. Treatments may include antibiotics, fluids, and oxygen therapy. Prevent sepsis by practicing good hygiene, getting vaccinated, and managing chronic conditions. Help raise awareness to protect yourself and loved ones. Stay informed, act swiftly, and together we can combat sepsis!</p>
                    </div>

                </div>
            </div>
            <div className='container mt-4'>
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '5px' }}>
                    <span style={{ color: "#ff7800" }}>understanding Sepsis</span>
                </h1>

                <div className="row">

                    <div className="col-lg-7  YoutubeContent mt-5" data-aos="fade-up" data-aos-duration="3000" >
                        <p><b>A Video by Dr. Kalyan Chakravarthy Koganti</b></p>
                        <p style={{ textAlign: 'justify' }}>
                            In this educational video by Dr. Kalyan Chakravarthy Koganti, an expert in critical care medicine, viewers will gain valuable insights into the potentially life-threatening condition known as sepsis. Dr. Koganti explains the mechanisms behind sepsis and why it can be so dangerous to the human body.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            The video delves into the concept of sepsis as a severe systemic response to infection, wherein the body's immune system goes into overdrive, leading to widespread inflammation and organ dysfunction. Dr. Koganti discusses the various causes of sepsis, which may include bacterial, viral, fungal, or parasitic infections.
                        </p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 mt-5" data-aos="fade-down" data-aos-duration="3000" >
                        <iframe width="100%" height="300px" src="https://www.youtube.com/embed/G_HaLRlm7EY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>

                </div>
            </div>




            {/* testmonials...... */}

            <div className='container' >
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Testmonials</span>
                </h1>
                <p style={{ textAlign: 'center' }}>Our team of experienced and accomplished doctors always give their best in providing ethical, modern and evidence based patient-centric treatment and always ensure health and safety of our patients.</p>
            </div>
            <div data-aos="zoom-in-up" data-aos-duration="3000" >
                <Testmonialss />
            </div>



            {/* Events and news..... */}
            <div className='container mb-4' id="events" data-aos="zoom-in" data-aos-duration="2000">
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "#ff7800" }}>Latest Friday ID Message</span>
                </h1>
            </div>
            {/* <div className='container text-center EventsCards' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '40px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="dengue.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>Dengue Fever</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Dengue_Fever_Treatment_In_Guntur'>Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="typhoid-fever.png" alt='' />
                            <Card.Body>
                                <Card.Title>Typhoid Fever</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Typhoid_Fever_Treatment_In_Guntur'>Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="scrub-typhus.png" alt='' />
                            <Card.Body>
                                <Card.Title>How To Identify Scrub Typhus And How To prevent It</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/How_To_Identify_Scrub_Typhus_And_how_to_Prevent_It'>Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>

                </div>
            </div> */}

            <div className='container'>
                <center>
                    <div className='row mt-5'>
                        <div className='col col-sm-12'>
                            <div className='card' style={{ width: '18rem' }}>
                                <img src='web.whatsapp.png' alt=''></img>
                                <a href='/Friday_Id_Message_Recent_National_Conference_On_HIV' className='mb-2 text-center' style={{ textDecoration: 'none', color: 'rgb(13, 56, 67)', textTransform: 'uppercase', marginTop: '-20px' }}>Recent National Conference on HIV</a>
                            </div>
                        </div>

                    </div>
                    <div className='row mt-3'>
                        <div className='col col-sm-12'>
                            <a href='Friday_Id_Day' className='FridayIdMoreInformation'><button >For More Information Click On Here</button></a>
                        </div>
                    </div>
                </center>
            </div>

            <div className='container mt-5'>
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Latest Videos</span>
                </h1>
                <div className='row mt-3'>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/dig6lF-oF2I?si=3psx3eslAKsiysOw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/QdQU-lnnquk?si=2ibLRWg-53kFo4eH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ZV6G0AUOllc?si=TnJsN4TXx1nXEYLa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mb-4'>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/-5JMpg0QVPw?si=wjtNM05RUh0kzZ3d" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullscreen style={{ borderRadius: '10px' }}></iframe>
                    </div>
                </div>
            </div>

        </>

    )

}

export default Home