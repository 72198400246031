import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import videoBg from './assets/kalyansirMp4.mp4'
import Videobg2 from './assets/kalyan sir 28 sec.mp4'

const News = () => {
  return (
    <>
      <div id='Topmargin'></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>Media & News</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
          </ul>
        </div>
      </div>
      <div className='container text-center mt-5 EventsCards' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '40px', justifyContent: 'center' }}>
        <div className='row text-center' style={{ justifyContent: 'center' }}>

        <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card id='newsCard'>
              <Card.Img variant="top" src="NEWS 1.jpeg" alt='' height={300}/>
              <Card.Body>
                <Card.Title style={{ fontSize: '17px' }} ></Card.Title>
                <Card.Text></Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                {/* <Link to='/smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh'> Know More</Link> */}
              </Card.Body>
            </Card>
          </div>

          <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card >
              <Card.Img variant="top" src="News2.png" alt='' height={300} />
              <Card.Body>
                <Card.Title style={{ fontSize: '17px' }} ></Card.Title>
                <Card.Text></Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                {/* <Link to='/smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh'> Know More</Link> */}
              </Card.Body>
            </Card>
          </div>
          <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card >
             <video src={videoBg} autoPlay loop muted ></video>
             <Card.Text className='mt-4 mb-4'>Dr. Kalyan Chakravarthy felicitate for sharing his knowledge on antibiotic resistance at South Zone APICON, Vishakapatnam</Card.Text>
            </Card>

          </div>

          <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card >
              <Card.Img variant="top" src="news_sepsis.png" alt='' height={180}/>
              <Card.Body>
                <Card.Title style={{ fontSize: '17px' }} ></Card.Title>
                <Card.Text>Is sepsis fatal?</Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                {/* <Link to='/smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh'> Know More</Link> */}
              </Card.Body>
            </Card>

          </div>
          <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card >
             <video src={Videobg2} autoPlay loop muted ></video>
             <Card.Text className='mt-3 mb-3'>smart ICU launched at Vinukonda</Card.Text>
            </Card>

          </div>

         

          <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card style={{ height: '100%' }}>
              <Card.Img variant="top" src="Patients-in-remote-area.jpeg" alt='' height={100}/>
              <Card.Body>
                <Card.Title>"Patients in remote areas being treated through smart ICU programme in Guntur</Card.Title>
                <Card.Text>

                </Card.Text>
                <Link to='/Patients_in_remote_areas_being_treated_through_smart_ICU_programme_in_Guntur'> Know More</Link>
              </Card.Body>
            </Card>
          </div>

          <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card >
              <Card.Img variant="top" src="WhatsApp Image 2023-10-03 at 10.00.38 AM1.jpeg" alt='' height={150}/>
              <Card.Body>
                <Card.Title style={{ fontSize: '17px' }} >"Revolutionizing Rural Healthcare: Dr. Kalyan Chakravarthy Leads the Way with AI-Driven Live ICU in Guntur"</Card.Title>
                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh'> Know More</Link>

              </Card.Body>

               <Card.Img variant="top" src="PAPER1.jpg" alt='' />
            </Card>

          </div>


         


          {/* <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card style={{}}>
              <Card.Img variant="top" src="PAPER1.jpg" alt='' />
              <Card.Body>
                <Card.Title></Card.Title>
                <Card.Text>
                </Card.Text>
              </Card.Body>
            </Card>
          </div> */}
          <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card style={{}}>
              <Card.Img variant="top" src="paper4.jpeg" alt='' />
              <Card.Body>

                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}

              </Card.Body>
            </Card>
          </div>



          <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card style={{}}>
              <Card.Img variant="top" src="WhatsApp Image 2022-11-10 at 9.45.30 AM (4).jpeg" alt='' />
              <Card.Body>

                <Card.Text>
                </Card.Text>

              </Card.Body>
              <Card.Img variant="top" src="WhatsApp Image 2022-11-10 at 9.45.30 AM (2).jpeg" alt='' />

            </Card>
          </div>
          <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card style={{}}>
              <Card.Img variant="top" src="WhatsApp Image 2022-11-10 at 9.41.46 AM.jpeg" alt='' />
              <Card.Body>
              </Card.Body>
            </Card>
          </div>
          <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card style={{}}>
              <Card.Img variant="top" src="WhatsApp Image 2022-11-10 at 9.45.30 AM (3).jpeg" alt='' height={280}/>
              <Card.Body>

                <Card.Text>
                </Card.Text>

              </Card.Body>
              <Card.Img variant="top" src="WhatsApp Image 2022-11-10 at 9.41.46 AM (1).jpeg" alt='' height={280}/>
            </Card>
          </div>
          {/* 
              <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
                <Card style={{}}>
                  <Card.Img variant="top" src="WhatsApp Image 2022-11-10 at 9.41.46 AM (1).jpeg" alt='' />
                  <Card.Body>
                  </Card.Body>
                </Card>
              </div> */}

          {/* 
              <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
                <Card style={{}}>
                  <Card.Img variant="top" src="WhatsApp Image 2022-11-10 at 9.45.30 AM (2).jpeg" alt='' />
                  <Card.Body>
    
                  </Card.Body>
                </Card>
              </div> */}
          <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card style={{}}>
              <Card.Img variant="top" src="WhatsApp Image 2022-11-10 at 9.42.43 AM.jpeg" alt='' height={550}/>
              <Card.Body>

                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}

              </Card.Body>
            </Card>
          </div>
          <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card style={{}}>
              <Card.Img variant="top" src="WhatsApp Image 2022-11-10 at 9.45.30 AM (1).jpeg" alt='' height={470}/>
              <Card.Body>

                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}

              </Card.Body>
            </Card>
          </div>
          <div className='col-md-4 col-sm-12 mb-2 mt-2 newsCard'>
            <Card style={{}}>
              <Card.Img variant="top" src="WhatsApp Image 2022-11-10 at 9.45.30 AM.jpeg" alt='' />
              <Card.Body>

              </Card.Body>
            </Card>
          </div>

        </div>
      </div>

    </>
  )
}

export default News